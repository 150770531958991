import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { ChromeMessageType } from '../../types/chromeMessage.types';
import { notifyExtension } from '../../utils/notifyExtension';
import { ChromeIcon, CrossIcon, LinkIcon } from './icons';

const ChromeExtensionBanner: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isExtensionUninstalled, setIsExtensionUninstalled] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const attrs = {
    container: {
      className: `chrome-extension-banner${isClosing ? ' closing' : ''}`,
      onAnimationEnd: () => {
        if (isClosing) {
          setIsOpened(false);
          setIsClosing(false);
        }
      },
    },
    contentWrapper: {
      className: 'chrome-extension-banner__content-wrapper',
    },
    icon: {
      className: 'chrome-extension-banner__icon',
    },
    link: {
      rel: 'noopener noreferrer',
      target: '_blank',
      className: 'chrome-extension-banner__link',
      href:
        'https://chromewebstore.google.com/detail/substrata-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka',
    },
    closeButton: {
      type: 'button' as const,
      className: 'chrome-extension-banner__close-button',
      onClick: () => {
        setIsExtensionUninstalled(false);
        setIsClosing(true);
      },
    },
  };

  useEffect(() => {
    if (
      !isInitialized &&
      user &&
      !user.showInstallExtensionPopup &&
      isExtensionUninstalled
    ) {
      setIsInitialized(true);
      setIsOpened(true);
    }
  }, [isExtensionUninstalled, isInitialized, user]);

  useEffect(() => {
    if (!window.chrome?.runtime) {
      setIsExtensionUninstalled(true);
    }
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isExtensionUninstalled) {
      interval = setInterval(() => {
        notifyExtension({
          type: ChromeMessageType.PING,
          onResponse: () => {
            setIsExtensionUninstalled(false);
            setIsClosing(true);
          },
        });
      }, 15000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isExtensionUninstalled]);

  if (!isOpened) {
    return null;
  }

  return (
    <div {...attrs.container}>
      <div {...attrs.contentWrapper}>
        <ChromeIcon {...attrs.icon} />
        <span>You haven’t installed the Chrome Extension.</span>
        <a {...attrs.link}>
          <span>Add it now</span>
          <LinkIcon />
        </a>
      </div>
      <div {...attrs.contentWrapper}>
        <button {...attrs.closeButton}>
          <CrossIcon {...attrs.icon} />
        </button>
      </div>
    </div>
  );
};

export default ChromeExtensionBanner;
