import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Features, Organization, User, UserConnection } from '../../types';
import {
  disconnectZoom,
  getCustomToken,
  getIdentity,
  getUserActivity,
  getUserConnections,
  getUserData,
  getZoomOAuthUrl,
  revokeGoogleScopes,
  sendDontShowInstallExtensionPopup,
  sendSupportRequest,
  sendZoomAuthCode,
  signOut,
  toggleApp,
  updateNewUser,
  viewWelcomeModal,
} from './auth.thunks';
import { AuthState } from './auth.types';

const initialState: AuthState = {
  isLoading: {
    signIn: false,
    customToken: false,
    identity: false,
    userConnections: false,
    signOut: false,
    userData: false,
    userActivity: false,
    toggleApp: false,
    supportRequest: false,
    welcomeModal: false,
    dontShowInstallExtensionPopup: false,
    connectAnalyzeEmails: false,
    connectSendEmails: false,
    connectCalendar: false,
    zoomOAuthUrl: false,
    zoomAuthCode: false,
    disconnectZoom: false,
    revokeGoogleScopes: false,
  },
  isError: {
    signIn: false,
    customToken: false,
    identity: false,
    userConnections: false,
    signOut: false,
    userData: false,
    userActivity: false,
    toggleApp: false,
    supportRequest: false,
    welcomeModal: false,
    dontShowInstallExtensionPopup: false,
    connectAnalyzeEmails: false,
    connectSendEmails: false,
    connectCalendar: false,
    zoomOAuthUrl: false,
    zoomAuthCode: false,
    disconnectZoom: false,
    revokeGoogleScopes: false,
  },
  isLoggedIn: false,
  isInitialized: false,
  customToken: null,
  user: null,
  org: null,
  userSignUpFlow: null,
  isAddInfoModalActive: false,
  addDeatilsModal: {
    isModalActive: false,
    isLoading: false,
    isSuccessful: false,
    isError: false,
    initialValues: {
      accountName: '',
      title: '',
      phone: '',
      countryCode: '',
    },
  },
  wizardModal: {
    isWizardModalActive: false,
    contentIndex: 0,
  },
  onboardingModalActive: false,
  isAccountTeamTabActive: false,
  userActivity: null,
  userConnections: null,
  zoomOAuthUrl: null,
  retryConnectZoom: false,
  revokeGoogleScopesModal: {
    isOpened: false,
    connectionId: UserConnection.GmailWrite,
  },
  addChromeExtensionModal: {
    isOpened: false,
  },
};

// const checkIsAddDetailsModalNeeded = (user: User, org: Organization) => {
//   if (
//     (user.role === 'owner' && (!user.phoneNumber || !org.name)) ||
//     ((user.role === 'admin' || user.role === 'dealmaker') &&
//       (!user.phoneNumber || !user.title))
//   ) {
//     return true;
//   }

//   return false;
// };

const updateFeature = (user: User, featureName: Features) => {
  if (!user.metadata?.features) {
    return user;
  }

  const updatedFeatures = user.metadata.features.map((feature) => {
    if (feature.name === featureName) {
      return { ...feature, enabled: !feature.enabled };
    }
    return feature;
  });

  return {
    ...user,
    metadata: {
      ...user.metadata,
      features: updatedFeatures,
    },
  };
};

const checkIsAccountNamePrefilled = (user: User, org: Organization) => {
  if (user.role !== 'owner') {
    return 'not owner';
  }
  if (org.acquisitionSource === 'selfSignup') {
    return '';
  }
  return org.name;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = true;
      state.isLoggedIn = state.isLoggedIn || action.payload;
    },
    startSignIn: (state) => {
      state.isError.signIn = false;
      state.isLoading.signIn = true;
    },
    endSignIn: (state) => {
      state.isLoading.signIn = false;
      state.customToken = null;
    },
    throwSignInError: (state) => {
      state.isError.signIn = true;
    },
    startConnectAnalyzeEmails: (state) => {
      state.isError.connectAnalyzeEmails = false;
      state.isLoading.connectAnalyzeEmails = true;
    },
    endConnectAnalyzeEmails: (state) => {
      state.isLoading.connectAnalyzeEmails = false;
    },
    throwConnectAnalyzeEmailsError: (state) => {
      state.isError.connectAnalyzeEmails = true;
    },
    startConnectSendEmails: (state) => {
      state.isError.connectSendEmails = false;
      state.isLoading.connectSendEmails = true;
    },
    endConnectSendEmails: (state) => {
      state.isLoading.connectSendEmails = false;
    },
    throwConnectSendEmailsError: (state) => {
      state.isError.connectSendEmails = true;
    },
    startConnectCalendar: (state) => {
      state.isError.connectCalendar = false;
      state.isLoading.connectCalendar = true;
    },
    endConnectCalendar: (state) => {
      state.isLoading.connectCalendar = false;
    },
    throwConnectCalendarError: (state) => {
      state.isError.connectCalendar = true;
    },
    toggleAddInfoModal: (state, action: PayloadAction<boolean>) => {
      state.isAddInfoModalActive = action.payload;
    },
    openAddDetailsModal: (state) => {
      state.addDeatilsModal.isModalActive = true;
    },
    closeAddDetailsModal: (state) => {
      state.addDeatilsModal = initialState.addDeatilsModal;
      if (state.user) {
        state.user.showDashboardWelcomePopup = false;
      }
    },
    toggleWizardModal: (state, action: PayloadAction<boolean>) => {
      state.wizardModal.isWizardModalActive = action.payload;
    },
    updateWizardModalContentIndex: (state, action: PayloadAction<number>) => {
      state.wizardModal.contentIndex = action.payload;
    },
    toggleAccountTeamsSection: (state, action: PayloadAction<boolean>) => {
      state.isAccountTeamTabActive = action.payload;
    },
    toggleOnboardingModal: (state, action: PayloadAction<boolean>) => {
      state.onboardingModalActive = action.payload;
    },
    closeInstallExtensionPopup: (state) => {
      if (state.user) {
        state.user.showInstallExtensionPopup = false;
      }
    },
    openRevokeGoogleScopesModal: (
      state,
      action: PayloadAction<Exclude<UserConnection, UserConnection.Zoom>>
    ) => {
      state.revokeGoogleScopesModal.isOpened = true;
      state.revokeGoogleScopesModal.connectionId = action.payload;
    },
    closeRevokeGoogleScopesModal: (state) => {
      state.revokeGoogleScopesModal.isOpened = false;
    },
    openAddChromeExtensionModal: (state) => {
      state.addChromeExtensionModal.isOpened = true;
      state.addDeatilsModal = initialState.addDeatilsModal;
    },
    closeAddChromeExtensionModal: (state) => {
      state.addChromeExtensionModal.isOpened = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomToken.pending, (state) => {
      state.isLoading.customToken = true;
      state.isError.customToken = false;
    });
    builder.addCase(getCustomToken.fulfilled, (state, action) => {
      state.isLoading.customToken = false;
      state.customToken = action.payload;
    });
    builder.addCase(getCustomToken.rejected, (state, action) => {
      state.isLoading.customToken = false;
      state.isError.customToken = !action.meta.aborted;
    });
    builder.addCase(getIdentity.pending, (state) => {
      state.isLoading.identity = true;
      state.isError.identity = false;
    });
    builder.addCase(getIdentity.fulfilled, (state, action) => {
      state.isLoading.identity = false;
      state.user = action.payload.user;
      state.addDeatilsModal.initialValues.accountName = checkIsAccountNamePrefilled(
        action.payload.user,
        action.payload.org
      );
      state.addDeatilsModal.initialValues.title =
        action.payload.user.title || '';
      state.addDeatilsModal.initialValues.phone =
        action.payload.user.phoneNumber || '';
      state.addDeatilsModal.isModalActive =
        action.payload.user.showDashboardWelcomePopup;
      // state.addDeatilsModal.isModalActive = checkIsAddDetailsModalNeeded(
      //   action.payload.user,
      //   action.payload.org
      // );
      state.org = action.payload.org;
      state.userSignUpFlow = action.payload.userSignUpFlow;
      state.userConnections = action.payload.userConnections;
    });
    builder.addCase(getIdentity.rejected, (state, action) => {
      state.isLoading.identity = false;
      state.isError.identity = !action.meta.aborted;
    });
    builder.addCase(getUserConnections.pending, (state) => {
      state.isLoading.userConnections = true;
      state.isError.userConnections = false;
    });
    builder.addCase(getUserConnections.fulfilled, (state, action) => {
      state.isLoading.userConnections = false;
      state.userConnections = action.payload;
    });
    builder.addCase(getUserConnections.rejected, (state, action) => {
      state.isLoading.userConnections = false;
      state.isError.userConnections = !action.meta.aborted;
    });
    builder.addCase(getZoomOAuthUrl.pending, (state) => {
      state.isLoading.zoomOAuthUrl = true;
      state.isError.zoomOAuthUrl = false;
      state.isError.zoomAuthCode = false;
    });
    builder.addCase(getZoomOAuthUrl.fulfilled, (state, action) => {
      state.isLoading.zoomOAuthUrl = false;
      state.zoomOAuthUrl = action.payload;
    });
    builder.addCase(getZoomOAuthUrl.rejected, (state, action) => {
      state.isLoading.zoomOAuthUrl = false;
      state.isError.zoomOAuthUrl = !action.meta.aborted;
      state.retryConnectZoom = !state.retryConnectZoom;
    });
    builder.addCase(sendZoomAuthCode.pending, (state) => {
      state.isLoading.zoomAuthCode = true;
      state.isError.zoomAuthCode = false;
    });
    builder.addCase(sendZoomAuthCode.fulfilled, (state) => {
      state.isLoading.zoomAuthCode = false;
    });
    builder.addCase(sendZoomAuthCode.rejected, (state, action) => {
      state.isLoading.zoomAuthCode = false;
      state.isError.zoomAuthCode = !action.meta.aborted;
      state.retryConnectZoom = !state.retryConnectZoom;
    });
    builder.addCase(disconnectZoom.pending, (state) => {
      state.isLoading.disconnectZoom = true;
      state.isError.disconnectZoom = false;
    });
    builder.addCase(disconnectZoom.fulfilled, (state) => {
      state.isLoading.disconnectZoom = false;
    });
    builder.addCase(disconnectZoom.rejected, (state, action) => {
      state.isLoading.disconnectZoom = false;
      state.isError.disconnectZoom = !action.meta.aborted;
    });
    builder.addCase(revokeGoogleScopes.pending, (state) => {
      state.isLoading.revokeGoogleScopes = true;
      state.isError.revokeGoogleScopes = false;
    });
    builder.addCase(revokeGoogleScopes.fulfilled, (state) => {
      state.isLoading.revokeGoogleScopes = false;
    });
    builder.addCase(revokeGoogleScopes.rejected, (state, action) => {
      state.isLoading.revokeGoogleScopes = false;
      state.isError.revokeGoogleScopes = !action.meta.aborted;
    });
    builder.addCase(signOut.pending, (state) => {
      state.isLoading.signOut = true;
      state.isError.signOut = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.isLoading.signOut = false;
      state.isLoggedIn = false;
    });
    builder.addCase(signOut.rejected, (state, action) => {
      state.isLoading.signOut = false;
      state.isError.signOut = !action.meta.aborted;
      state.isLoggedIn = false;
    });
    builder.addCase(getUserData.pending, (state) => {
      state.isLoading.userData = true;
      state.isError.userData = false;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.isLoading.userData = false;
      state.user = action.payload;
      // state.user = updateFeature(action.payload, Features.SOUNDWAVE);
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.isLoading.userData = false;
      state.isError.userData = !action.meta.aborted;
    });
    builder.addCase(getUserActivity.pending, (state) => {
      state.isLoading.userActivity = true;
      state.isError.userActivity = false;
    });
    builder.addCase(getUserActivity.fulfilled, (state, action) => {
      state.isLoading.userActivity = false;
      state.userActivity = action.payload;
    });
    builder.addCase(getUserActivity.rejected, (state, action) => {
      state.isLoading.userActivity = false;
      state.isError.userActivity = !action.meta.aborted;
    });
    builder.addCase(toggleApp.pending, (state) => {
      state.isLoading.toggleApp = true;
      state.isError.toggleApp = false;
    });
    builder.addCase(toggleApp.fulfilled, (state, action) => {
      state.isLoading.toggleApp = false;

      if (state.user?.metadata?.features) {
        state.user.metadata.features = state.user.metadata.features.map(
          (feature) =>
            feature.name === action.payload.appName
              ? { ...feature, enabled: action.payload.active }
              : feature
        );
      }
    });
    builder.addCase(toggleApp.rejected, (state, action) => {
      state.isLoading.toggleApp = false;
      state.isError.toggleApp = !action.meta.aborted;
    });
    builder.addCase(sendSupportRequest.pending, (state) => {
      state.isLoading.supportRequest = true;
      state.isError.supportRequest = false;
    });
    builder.addCase(sendSupportRequest.fulfilled, (state) => {
      state.isLoading.supportRequest = false;
    });
    builder.addCase(sendSupportRequest.rejected, (state, action) => {
      state.isLoading.supportRequest = false;
      state.isError.supportRequest = !action.meta.aborted;
    });
    builder.addCase(viewWelcomeModal.pending, (state) => {
      state.isLoading.welcomeModal = true;
      state.isError.welcomeModal = false;
      if (state.user) {
        state.user.showDashboardWelcomePopup = false;
      }
    });
    builder.addCase(viewWelcomeModal.fulfilled, (state) => {
      state.isLoading.welcomeModal = false;
    });
    builder.addCase(viewWelcomeModal.rejected, (state, action) => {
      state.isLoading.welcomeModal = false;
      state.isError.welcomeModal = !action.meta.aborted;
    });
    builder.addCase(sendDontShowInstallExtensionPopup.pending, (state) => {
      state.isLoading.dontShowInstallExtensionPopup = true;
      state.isError.dontShowInstallExtensionPopup = false;
      if (state.user) {
        state.user.showInstallExtensionPopup = false;
      }
    });
    builder.addCase(sendDontShowInstallExtensionPopup.fulfilled, (state) => {
      state.isLoading.dontShowInstallExtensionPopup = false;
    });
    builder.addCase(
      sendDontShowInstallExtensionPopup.rejected,
      (state, action) => {
        state.isLoading.dontShowInstallExtensionPopup = false;
        state.isError.dontShowInstallExtensionPopup = !action.meta.aborted;
      }
    );
    builder.addCase(updateNewUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.addDeatilsModal = initialState.addDeatilsModal;
    });
    builder.addCase(updateNewUser.pending, (state) => {
      state.addDeatilsModal.isLoading = true;
      state.addDeatilsModal.isError = false;
    });
    builder.addCase(updateNewUser.rejected, (state, action) => {
      state.addDeatilsModal.isLoading = false;
      state.addDeatilsModal.isError = !action.meta.aborted;
    });
  },
});

export const {
  initAuth,
  startSignIn,
  endSignIn,
  throwSignInError,
  startConnectAnalyzeEmails,
  endConnectAnalyzeEmails,
  throwConnectAnalyzeEmailsError,
  startConnectSendEmails,
  endConnectSendEmails,
  throwConnectSendEmailsError,
  startConnectCalendar,
  endConnectCalendar,
  throwConnectCalendarError,
  toggleAddInfoModal,
  toggleWizardModal,
  updateWizardModalContentIndex,
  toggleAccountTeamsSection,
  toggleOnboardingModal,
  closeInstallExtensionPopup,
  openRevokeGoogleScopesModal,
  closeRevokeGoogleScopesModal,
  openAddDetailsModal,
  closeAddDetailsModal,
  openAddChromeExtensionModal,
  closeAddChromeExtensionModal,
} = authSlice.actions;

export default authSlice.reducer;
