import React, { useMemo } from 'react';
import { customArray } from 'country-codes-list';
import { hasFlag } from 'country-flag-icons';
import * as countryFlagIcons from 'country-flag-icons/react/1x1';
import { SelectOption } from '../components/Select/Select';
import { GlobeIcon } from '../../../../Icons/GlobeIcon';

const countryFlagIconsMap: Record<
  keyof typeof countryFlagIcons,
  (props: React.SVGAttributes<SVGElement>) => JSX.Element
> = countryFlagIcons;

const rawOptions = customArray({
  countryCallingCode: `+{countryCallingCode}`,
  countryName: `{countryNameEn}`,
  countryCode: `{countryCode}`,
});

export const useCountryPhoneCodeSelectOptions = (): SelectOption[] =>
  useMemo(
    () =>
      rawOptions
        .sort((a, b) => (a.countryName > b.countryName ? 1 : -1))
        .map(({ countryCallingCode, countryName, countryCode }) => {
          const props = {
            countryFlag: {
              className: 'form-field__country-flag-icon',
            },
            countryName: {
              className: 'form-field__country-name',
            },
          };

          const CountryFlagComponent = hasFlag(countryCode)
            ? countryFlagIconsMap[
                countryCode as keyof typeof countryFlagIconsMap
              ]
            : GlobeIcon;

          return {
            value: `${countryCode}|${countryCallingCode}`,
            label: (
              <span {...props.countryFlag}>
                <CountryFlagComponent />
              </span>
            ),
            dropdownLabel: (
              <>
                <span {...props.countryFlag}>
                  <CountryFlagComponent />
                </span>
                <span {...props.countryName}>
                  {countryName} ({countryCallingCode})
                </span>
              </>
            ),
            searchHints: [
              countryName.toLowerCase(),
              countryCallingCode,
              countryCallingCode.replace('+', ''),
            ],
          };
        }),
    []
  );
