import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { Organization, User, UserActivity, UserConnections } from '../../types';
import { ApiResponse, ApiResponseData } from '../../types/apiResponse.types';
import { RootState } from '../store';
import { GetCustomTokenRequestBody, UserSignUpFlow } from './auth.types';

export const getCustomToken = createAsyncThunk<string, string>(
  'auth/getCustomToken',
  async (authCode, { fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const response = await axios.post<
        ApiResponseData<{ customToken: string }>,
        ApiResponse<{ customToken: string }>,
        GetCustomTokenRequestBody
      >('/auth-code', { authCode }, { signal });

      return fulfillWithValue(response.data.payload.customToken);
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);

export const getIdentity = createAsyncThunk<{
  user: User;
  org: Organization;
  userSignUpFlow: UserSignUpFlow;
  userConnections: UserConnections | null;
}>(
  'auth/getIdentity',
  async (_, { fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const response = await axios.get<
        ApiResponseData<{
          user: User;
          org: Organization;
          state: UserSignUpFlow;
          connections: UserConnections | undefined;
        }>
      >(`/api/v1/identity`, {
        signal,
        params: {
          includeConnections: true,
        },
      });

      const { user, org, state, connections } = response.data.payload;

      return fulfillWithValue({
        user,
        org,
        userSignUpFlow: state,
        userConnections: connections || null,
      });
    } catch (error) {
      return rejectWithValue(null);
    }
  }
);

export const getUserConnections = createAsyncThunk<UserConnections>(
  'auth/getUserConnections',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const response = await axios.get<ApiResponseData<UserConnections>>(
        `/api/v1/auth/orgs/${user?.orgId}/users/${user?.userId}/connections`,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const getZoomOAuthUrl = createAsyncThunk<string>(
  'auth/getZoomOAuthUrl',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios.get<ApiResponseData<string>>(
        '/api/v1/auth/zoom/get-oauth-link'
      );

      return fulfillWithValue(response.data.payload);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const sendZoomAuthCode = createAsyncThunk<null, string>(
  'auth/sendZoomAuthCode',
  async (code, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      await axios.get('/api/v1/auth/zoom/oauth-callback', { params: { code } });
      dispatch(getUserConnections());
      return fulfillWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const disconnectZoom = createAsyncThunk<null>(
  'auth/disconnectZoom',
  async (_, { dispatch, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      await axios.get(`/api/v1/auth/zoom/deauthorize`, { signal });
      dispatch(getUserConnections());
      return fulfillWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);

export const revokeGoogleScopes = createAsyncThunk<null>(
  'auth/revokeGoogleScopes',
  async (_, { fulfillWithValue, rejectWithValue, signal }) => {
    try {
      await axios.get(`/api/v1/auth/disconnect/google`, { signal });
      return fulfillWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);

export const signOut = createAsyncThunk<null>(
  'auth/signOut',
  async (_, { fulfillWithValue, rejectWithValue, signal }) => {
    try {
      await axios.get('/logout', { signal });
      return fulfillWithValue(null);
    } catch (error) {
      return rejectWithValue(null);
    }
  }
);

export const getUserData = createAsyncThunk<User>(
  'auth/getUserData',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const response = await axios.get<ApiResponseData<User>>(
        `/services/orgs/${user?.orgId}/users/${user?.userId}?analyzedEmails=true&features=true`,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const getUserActivity = createAsyncThunk<UserActivity>(
  'auth/getUserActivity',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const response = await axios.get<ApiResponseData<UserActivity>>(
        `services/orgs/${user?.orgId}/users/${user?.userId}/activity`,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const toggleApp = createAsyncThunk<
  { active: boolean; appName: string },
  { active: boolean; appName: string }
>(
  'auth/toggleApp',
  async (
    { active, appName },
    { getState, fulfillWithValue, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { org },
      } = getState() as RootState;

      await axios.patch(
        `/services/orgs/${org?.orgId}/apps/${appName}`,
        {
          active,
        },
        { signal }
      );

      return fulfillWithValue({ active, appName });
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { org },
      } = getState() as RootState;

      return !!org;
    },
  }
);

export const sendSupportRequest = createAsyncThunk<null>(
  'auth/sendSupportRequest',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      await axios.post(
        `services/orgs/${user?.orgId}/users/${user?.userId}/support`,
        undefined,
        { signal }
      );

      return fulfillWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const viewWelcomeModal = createAsyncThunk<null>(
  'auth/updateWelcomeModalIsViewed',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      await axios.patch(
        `services/orgs/${user?.orgId}/users/${user?.userId}/welcome`,
        undefined,
        { params: { showPopup: false }, signal }
      );

      return fulfillWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const sendDontShowInstallExtensionPopup = createAsyncThunk<null>(
  'auth/sendDontShowInstallExtensionPopup',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      await axios.patch(
        `services/orgs/${user?.orgId}/users/${user?.userId}/flags`,
        {
          flags: [
            {
              name: 'showInstallExtensionPopup',
              value: false,
            },
          ],
        },
        { signal }
      );

      return fulfillWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const updateNewUser = createAsyncThunk<
  User,
  { title: string; phoneNumber: string }
>(
  'auth/updateNewUser',
  async (
    { title, phoneNumber },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.patch<ApiResponseData<User>>(
        `/services/orgs/${orgId}/users/${userId}`,
        { phoneNumber, title },
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
