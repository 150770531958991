import React from 'react';

const ChromeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1088_86819)">
      <path
        d="M13 18.9971C16.3137 18.9971 19 16.3108 19 12.9971C19 9.68336 16.3137 6.99707 13 6.99707C9.68629 6.99707 7 9.68336 7 12.9971C7 16.3108 9.68629 18.9971 13 18.9971Z"
        fill="white"
      />
      <path
        d="M12.9997 7.00002H23.3903C22.3374 5.17571 20.8228 3.66077 18.9987 2.60752C17.1746 1.55428 15.1053 0.999853 12.999 1C10.8926 1.00015 8.82342 1.55486 6.99946 2.60836C5.17549 3.66186 3.66105 5.17702 2.6084 7.00147L7.8037 16L7.80835 15.9988C7.27985 15.0875 7.00095 14.0529 6.99977 12.9994C6.99858 11.9458 7.27515 10.9106 7.8016 9.99807C8.32805 9.08552 9.08577 8.32789 9.99838 7.80153C10.911 7.27518 11.9462 6.99872 12.9997 7.00002Z"
        fill="url(#paint0_linear_1088_86819)"
      />
      <path
        d="M13 17.75C15.6234 17.75 17.75 15.6234 17.75 13C17.75 10.3766 15.6234 8.25 13 8.25C10.3766 8.25 8.25 10.3766 8.25 13C8.25 15.6234 10.3766 17.75 13 17.75Z"
        fill="#1A73E8"
      />
      <path
        d="M18.1955 16.0019L13.0002 25.0004C15.1065 25.0007 17.1758 24.4464 19 23.3933C20.8242 22.3402 22.339 20.8254 23.392 19.0011C24.445 17.1769 24.9992 15.1076 24.9988 13.0013C24.9985 10.8949 24.4435 8.8258 23.3898 7.00195H12.9993L12.998 7.0066C14.0516 7.00457 15.087 7.28032 16 7.80604C16.9129 8.33177 17.6712 9.0889 18.1983 10.0011C18.7253 10.9133 19.0026 11.9483 19.0021 13.0018C19.0016 14.0554 18.7234 15.0901 18.1955 16.0019Z"
        fill="url(#paint1_linear_1088_86819)"
      />
      <path
        d="M7.80444 16.0015L2.60914 7.00293C1.5557 8.82693 1.00106 10.8961 1.00098 13.0025C1.0009 15.1088 1.55539 17.1781 2.60869 19.0022C3.66199 20.8262 5.17698 22.3408 7.00132 23.3937C8.82565 24.4465 10.895 25.0005 13.0014 24.9999L18.1967 16.0014L18.1933 15.998C17.6683 16.9114 16.9118 17.6702 16 18.198C15.0882 18.7258 14.0534 19.0038 12.9999 19.0042C11.9464 19.0046 10.9114 18.7272 9.99925 18.2C9.08711 17.6728 8.33007 16.9145 7.80444 16.0015Z"
        fill="url(#paint2_linear_1088_86819)"
      />
    </g>
    <path
      d="M13 0.5C6.09644 0.5 0.5 6.09644 0.5 13C0.5 19.9036 6.09644 25.5 13 25.5C19.9036 25.5 25.5 19.9036 25.5 13C25.5 6.09644 19.9036 0.5 13 0.5Z"
      stroke="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1088_86819"
        x1="2.6084"
        y1="8.50002"
        x2="23.3903"
        y2="8.50002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D93025" />
        <stop offset="1" stopColor="#EA4335" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1088_86819"
        x1="11.3608"
        y1="24.84"
        x2="21.7518"
        y2="6.84225"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCC934" />
        <stop offset="1" stopColor="#FBBC04" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1088_86819"
        x1="14.2992"
        y1="24.2507"
        x2="3.90819"
        y2="6.25293"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1E8E3E" />
        <stop offset="1" stopColor="#34A853" />
      </linearGradient>
      <clipPath id="clip0_1088_86819">
        <path
          d="M1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ChromeIcon;
