import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { useId } from '../../../hooks';
import {
  FormFieldProps,
  FormFieldType,
} from '../../SoundWave/components/FormField/FormField';
import { FormProps } from '../../SoundWave/components/Form/Form';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ModalProps } from '../../SoundWave/components/Modal/Modal';
import { GlobeIcon } from '../../Icons/GlobeIcon';
import { StatusCode } from '../../../types/statusCode.types';
import { AddDetailsFormValues } from '../../../store/auth/auth.types';
import { closeAddDetailsModal } from '../../../store/auth/auth.slice';
import { User } from '../../../types';
import { updateOrganization } from '../../../store/org/org.actions';
import {
  updateNewUser,
  viewWelcomeModal,
} from '../../../store/auth/auth.thunks';

const validationSchema = Yup.object().shape({
  accountName: Yup.string()
    .required('This field is required')
    .typeError('This is a required field')
    .matches(/[a-zA-Z+]/, 'The value in this field must start with a letter')
    .matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Too short',
    })
    .max(256, 'Too long'),
  title: Yup.string().max(256, 'Too long'),
  countryCode: Yup.string(),
  phone: Yup.string()
    .min(6, 'Please enter a valid phone number')
    .max(14, 'Too long phone number'),
});

export const useAddDetailsForm = (): {
  modalProps: ModalProps;
  formProps: FormProps;
  fieldProps: Record<
    keyof Omit<AddDetailsFormValues, 'countryCode'>,
    FormFieldProps
  >;
  values: AddDetailsFormValues;
  user: User | null;
} => {
  const dispatch = useAppDispatch();

  const { user, org } = useAppSelector((state) => state.auth);

  const isOpened = useAppSelector(
    (state) => state.auth.addDeatilsModal.isModalActive
  );

  const isEditDetailsLoading = useAppSelector(
    (state) => state.auth.addDeatilsModal.isLoading
  );

  const initialValues = useAppSelector(
    (state) => state.auth.addDeatilsModal.initialValues
  );

  const {
    errors,
    touched,
    values,
    isValid,
    dirty,
    validateField,
    handleBlur,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik<AddDetailsFormValues>({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: async (formValues, helpers) => {
      try {
        if (user) {
          if (user.role === 'owner' && org?.name !== formValues.accountName) {
            dispatch(
              updateOrganization(
                { name: formValues.accountName },
                user.orgId,
                true
              )
            );
          }

          if (
            (user.phoneNumber !== formValues.phone && formValues.phone) ||
            (user?.title !== formValues.title && formValues.title)
          ) {
            const userInfoToUpdate = {
              title: formValues.title,
              phoneNumber: formValues.phone,
            };
            dispatch(updateNewUser(userInfoToUpdate));
            dispatch(viewWelcomeModal());
          } else {
            dispatch(viewWelcomeModal());
          }
        }
      } catch (error) {
        if (
          error instanceof AxiosError &&
          error.response?.status === StatusCode.Conflict
        ) {
          helpers.setFieldError('accountName', 'err');
        }
      }
    },
  });

  const formId = useId();

  const handleChange = useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);
      validateField(name);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (user && user?.role !== 'owner') {
      setFieldValue('title', 'not owner');
    }
  }, [user]);

  const handleFocus = useCallback(
    (e: React.FocusEvent<any, Element>) => {
      setFieldError(e.target.name, '');
      setFieldTouched(e.target.name, false);
    },
    [setFieldError, setFieldTouched]
  );

  // console.log('values', values, isValid, dirty);

  const modalProps = useMemo(
    () => ({
      isOpened,
      closeOnClickOutside: false,
      width: 448,
      className: 'add-details-modal',
      onClose: () => {},
      onAnimationEnd: () => {
        dispatch(closeAddDetailsModal());
      },
      headerProps: {
        title: 'Please complete your profile',
        isCloseIconHidden: true,
      },
      footerProps: {
        formId,
        isLoading: isEditDetailsLoading,
        isCancelButtonHidden: true,
        confirmButtonText: 'Continue',
        isConfirmButtonDisabled: !isValid,
        onConfirm: handleSubmit,
      },
    }),
    [dirty, dispatch, formId, isEditDetailsLoading, isOpened, isValid]
  );

  const formProps = useMemo(
    () => ({
      id: formId,
      onSubmit: handleSubmit,
    }),
    [formId, handleSubmit]
  );

  const fieldProps = useMemo(
    () => ({
      accountName: {
        type: FormFieldType.TEXT,
        name: 'accountName',
        placeholder: 'Business Name',
        label: 'Account Name',
        value: values.accountName,
        required: true,
        error: errors.accountName,
        touched: touched.accountName,
        disabled: false,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
      title: {
        type: FormFieldType.TEXT,
        name: 'title',
        placeholder: 'Your Role / Position',
        label: 'Job Title:',
        value: values.title,
        error: errors.title,
        touched: touched.title,
        disabled: false,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
      phone: {
        type: FormFieldType.PHONE,
        name: 'phone',
        selectName: 'countryCode',
        placeholder: '+X XXX XXX XXX',
        selectPlaceholder: <GlobeIcon />,
        label: 'Phone Number:',
        touched: touched.phone,
        value: values.phone,
        selectValue: values.countryCode,
        error: errors.phone,
        disabled: false,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
    }),
    [
      errors,
      handleBlur,
      handleChange,
      handleFocus,
      touched,
      user,
      values,
      initialValues,
      org,
    ]
  );

  useEffect(() => {
    resetForm({ values: initialValues, errors: {}, touched: {} });
  }, [initialValues, resetForm]);

  return {
    modalProps,
    formProps,
    fieldProps,
    values,
    user,
  };
};
