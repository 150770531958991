import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from '../../axios';
import { MeetingDetails } from '../../types/meetingDetails.types';
import { ApiResponse, ApiResponseData } from '../../types/apiResponse.types';
import {
  RelationshipMemberSide,
  RelationshipMemberAssociationType,
} from '../../types/relationshipMember.types';
import { Contact } from '../../types/contact.types';

export const getMeetingDetails = createAsyncThunk<MeetingDetails, string>(
  'meetingDetails/getMeetingDetails',
  async (
    meetingId,
    { getState, fulfillWithValue, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<ApiResponseData<MeetingDetails>>(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/online-meetings/${meetingId}`,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const changeParticipantSide = createAsyncThunk<
  Contact,
  {
    participantId: string;
    accountId: string;
    contactId: string;
    side: RelationshipMemberSide;
  }
>(
  'meetingDetails/changeParticipantSide',
  async (
    { accountId, contactId, side },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<Contact>,
        ApiResponse<Contact>,
        {
          accountId: string;
          contactSide: string;
          associationType: RelationshipMemberAssociationType;
        }
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/contacts/${contactId}/links`,
        {
          accountId: accountId || '',
          contactSide: side,
          associationType: RelationshipMemberAssociationType.TEAM_MEMBER,
        },
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const updateMeetingTitle = createAsyncThunk<void, string>(
  'meetingDetails/updateMeetingTitle',
  async (title, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
        meetingDetails: { data },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const { meetingId = '' } = data || {};

      await axios.patch<
        ApiResponseData<void>,
        ApiResponse<void>,
        { meetingTitle: string }
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/online-meetings/${meetingId}`,
        { meetingTitle: title },
        { signal }
      );

      return fulfillWithValue(undefined);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
        meetingDetails: { data },
      } = getState() as RootState;
      return !!user && !!data;
    },
  }
);

export const getMeetingRecordingUrl = createAsyncThunk<string, string>(
  'meetingDetails/getMeetingRecordingUrl',
  async (
    meetingId,
    { getState, fulfillWithValue, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<
        ApiResponseData<{ recordingUrl: string }>
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/online-meetings/${meetingId}/recording-url`,
        { signal }
      );

      return fulfillWithValue(response.data.payload.recordingUrl);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
