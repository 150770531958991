import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CrossIcon } from './icons/CrossIcon';

export interface ModalHeaderProps {
  title?: string;
  onClose: () => void;
  isCloseIconHidden?: boolean;
  headerTitleCSSTransitionClassName?: string;
  headerTitleCSSTransitionIndex?: number | null;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  onClose,
  isCloseIconHidden = false,
  headerTitleCSSTransitionClassName,
  headerTitleCSSTransitionIndex = null,
}) => {
  const attrs = {
    container: {
      className: 'sound-wave-modal-header',
    },
    button: {
      'aria-label': 'Close',
      type: 'button' as const,
      className: 'sound-wave-modal-header__button',
      onClick: onClose,
    },
    buttonIcon: {
      className: 'sound-wave-modal-header__button-icon',
    },
  };

  const titleWithSubTile =
    title === 'Getting Started with SoundWave' ? (
      <>
        <span>
          {title}
          <span id="subtitle">
            Unlock powerful meeting insights in a few simple steps
          </span>
        </span>
      </>
    ) : (
      <span>{title}</span>
    );

  const titleWithTransition =
    typeof headerTitleCSSTransitionIndex === 'number' &&
    headerTitleCSSTransitionClassName?.length ? (
      <TransitionGroup>
        <CSSTransition
          key={headerTitleCSSTransitionIndex}
          timeout={300}
          classNames={headerTitleCSSTransitionClassName}
        >
          {titleWithSubTile}
        </CSSTransition>
      </TransitionGroup>
    ) : (
      <span>{title}</span>
    );

  return (
    <div {...attrs.container}>
      {title ? titleWithTransition : null}
      {!isCloseIconHidden ? (
        <button {...attrs.button}>
          <CrossIcon {...attrs.buttonIcon} />
        </button>
      ) : null}
    </div>
  );
};
