import React from 'react';
import { Form, FormField, Modal } from '../SoundWave';
import { useAddDetailsForm } from './hooks/useAddDetailsForm';

export const AddDetailsModal: React.FC = () => {
  const { modalProps, formProps, fieldProps, user } = useAddDetailsForm();

  const attrs = {
    fieldset: {
      className: 'add-details-modal__fieldset',
    },
    fieldsGroup: {
      className: 'add-details-modal__fields-group',
    },
  };

  return (
    <>
      <Modal {...modalProps}>
        <Form {...formProps}>
          <fieldset {...attrs.fieldset}>
            {user?.role === 'owner' ? (
              <FormField {...fieldProps.accountName} />
            ) : null}
            <FormField {...fieldProps.title} />
            <FormField {...fieldProps.phone} />
          </fieldset>
        </Form>
      </Modal>
    </>
  );
};
