import { EXTENSION_ID } from '../constants/config';
import {
  ChromeMessage,
  ChromeMessagePayloadMap,
  ChromeMessageType,
} from '../types/chromeMessage.types';

export const notifyExtension = <T extends ChromeMessageType>({
  type,
  payload = null,
  onResponse,
  onError,
}: {
  type: T;
  payload?: ChromeMessagePayloadMap[T];
  onResponse?: (response: any) => void;
  onError?: (error: chrome.runtime.LastError | undefined) => void;
}): void => {
  if (window.chrome?.runtime) {
    const message: ChromeMessage<T> = {
      type,
      payload,
    };

    window.chrome.runtime.sendMessage(EXTENSION_ID, message, (response) => {
      if (response) {
        onResponse?.(response);
      } else {
        onError?.(window.chrome.runtime.lastError);
      }
    });
  }
};
