import React, { useEffect, useState } from 'react';
import { ConfirmationModal, Form, FormField, Modal } from '../SoundWave';
import { useAddMemberForm } from './hooks/useAddMemberForm';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  updateAddMemberFormValues,
  updateExistingContact,
  updateIsEditingExistingContact,
} from '../../store/relationshipFlows/relationshipFlows.slice';
import { ContactItem } from './components/ContactItem/ContactItem';
import { ExistingContactDisclaimer } from './components/ExistingContactDisclaimer/ExistingContactDisclaimer';
import { RelationshipMemberAssociationType } from '../../types/relationshipMember.types';

const ASSOCIATED_ACCOUNTS_TO_SHOW = 2;

export const AddMemberModal: React.FC = () => {
  const [isAssociationsExpanded, setAssociationsExpanded] = useState(false);
  const dispatch = useAppDispatch();

  const isEditFlow = useAppSelector(
    (state) => state.relatioshipFlows.addMember.isEditFlow
  );

  const isEditingExistingContact = useAppSelector(
    (state) => state.relatioshipFlows.addMember.isEditingExistingContact
  );

  const existingContact = useAppSelector(
    (state) => state.relatioshipFlows.addMember.existingContact
  );

  const { linkedAccounts } = existingContact?.extra || {};

  const {
    modalProps,
    formProps,
    fieldProps,
    cancelModalProps,
    confirmationModalProps,
    values,
    resetContactFields,
  } = useAddMemberForm();

  useEffect(() => {
    if (!modalProps.isOpened) {
      setAssociationsExpanded(false);
    }
  }, [modalProps.isOpened]);

  const existingContactDisclaimerText = isEditingExistingContact
    ? 'Changes to this contact will apply across all existing records.'
    : 'This email already exists and has previous associations:';

  const editFlowExistingContactDisclaimerText = isEditingExistingContact
    ? 'Changes to this contact will apply across all existing records.'
    : 'This email address has previous associations.';

  const attrs = {
    fieldset: {
      className: 'sound-wave-form__fieldset',
    },
    fieldsGroup: {
      className: 'sound-wave-form__fields-group',
    },
    contactItem: {
      text: existingContact?.email || 'no email detected' || '',
      secondaryText: existingContact?.metadata?.name || '',
      imageSrc: existingContact?.metadata?.avatarSrc || '',
      isEditing: isEditingExistingContact,
      onEdit: (isEditing: boolean) => {
        dispatch(updateIsEditingExistingContact(!isEditing));

        if (isEditing) {
          resetContactFields();
        }
      },
      onRemove: () => {
        dispatch(updateIsEditingExistingContact(false));
        dispatch(updateExistingContact(null));
        dispatch(
          updateAddMemberFormValues({
            ...values,
            title: '',
            avatar: '',
            firstName: '',
            lastName: '',
            phone: '',
          })
        );
      },
    },
    accountsAmount: {
      className: 'existing-contact-disclaimer__accounts-amount',
      onClick: () => setAssociationsExpanded(true),
    },
    existingContactDisclaimer: {
      text: isEditFlow
        ? editFlowExistingContactDisclaimerText
        : existingContactDisclaimerText,
    },
  };

  const renderAssociatedAccounts = () => {
    const checkedLinkedAccounts = isAssociationsExpanded
      ? linkedAccounts
      : linkedAccounts?.slice(0, ASSOCIATED_ACCOUNTS_TO_SHOW);

    return checkedLinkedAccounts?.map(({ account, link }) => {
      const props = {
        key: account.accountId,
        isAccount: true,
        showSecondaryText: true,
        text: account.name,
        ...(link.associationType && {
          secondaryText:
            link.associationType ===
            RelationshipMemberAssociationType.TEAM_MEMBER
              ? 'Team member'
              : '3rd Party',
        }),
      };

      return <ContactItem {...props} />;
    });
  };

  return (
    <>
      <Modal {...modalProps}>
        <Form {...formProps}>
          <fieldset {...attrs.fieldset}>
            {existingContact ? (
              <>
                <ContactItem {...attrs.contactItem} />
                {linkedAccounts?.length ? (
                  <ExistingContactDisclaimer
                    {...attrs.existingContactDisclaimer}
                  >
                    {renderAssociatedAccounts()}
                    {linkedAccounts.length > ASSOCIATED_ACCOUNTS_TO_SHOW &&
                    !isAssociationsExpanded ? (
                      <div {...attrs.accountsAmount}>
                        +{linkedAccounts.length - ASSOCIATED_ACCOUNTS_TO_SHOW}
                      </div>
                    ) : null}
                  </ExistingContactDisclaimer>
                ) : null}
              </>
            ) : null}
            <FormField {...fieldProps.email} />
            <div {...attrs.fieldsGroup}>
              <FormField {...fieldProps.firstName} />
              <FormField {...fieldProps.lastName} />
            </div>
            <div {...attrs.fieldsGroup}>
              <FormField {...fieldProps.avatar} />
              <FormField {...fieldProps.title} />
            </div>
            <FormField {...fieldProps.phone} />
          </fieldset>
          <fieldset {...attrs.fieldset}>
            <FormField {...fieldProps.assignedTo} />
            <FormField {...fieldProps.contactType} />
            <FormField {...fieldProps.note} />
          </fieldset>
        </Form>
      </Modal>
      <ConfirmationModal {...cancelModalProps} />
      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
};
