import React from 'react';

const LinkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.66634 3.33325C2.66634 2.96506 2.96482 2.66659 3.33301 2.66659H7.99967C8.36786 2.66659 8.66634 2.36811 8.66634 1.99992C8.66634 1.63173 8.36786 1.33325 7.99967 1.33325H3.33301C2.22844 1.33325 1.33301 2.22868 1.33301 3.33325V12.6666C1.33301 13.7712 2.22844 14.6666 3.33301 14.6666H12.6663C13.7709 14.6666 14.6663 13.7712 14.6663 12.6666V7.99992C14.6663 7.63173 14.3679 7.33325 13.9997 7.33325C13.6315 7.33325 13.333 7.63173 13.333 7.99992V12.6666C13.333 13.0348 13.0345 13.3333 12.6663 13.3333H3.33301C2.96482 13.3333 2.66634 13.0348 2.66634 12.6666V3.33325Z"
      fill="white"
    />
    <path
      d="M9.99967 1.99992C9.99967 1.63173 10.2982 1.33325 10.6663 1.33325H13.333C14.0694 1.33325 14.6663 1.93021 14.6663 2.66659V5.33325C14.6663 5.70144 14.3679 5.99992 13.9997 5.99992C13.6315 5.99992 13.333 5.70144 13.333 5.33325V3.6094L8.47108 8.47132C8.21073 8.73167 7.78862 8.73167 7.52827 8.47132C7.26792 8.21097 7.26792 7.78886 7.52827 7.52851L12.3902 2.66659H10.6663C10.2982 2.66659 9.99967 2.36811 9.99967 1.99992Z"
      fill="white"
    />
  </svg>
);

export default LinkIcon;
