import React from 'react';

const ChromeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="80"
    viewBox="0 0 32 80"
    fill="none"
    {...props}
  >
    <path
      d="M2.5 14C2.5 6.54416 8.54416 0.5 16 0.5C23.4558 0.5 29.5 6.54416 29.5 14C29.5 21.4558 23.4558 27.5 16 27.5C8.54416 27.5 2.5 21.4558 2.5 14Z"
      fill="white"
    />
    <path
      d="M2.5 14C2.5 6.54416 8.54416 0.5 16 0.5C23.4558 0.5 29.5 6.54416 29.5 14C29.5 21.4558 23.4558 27.5 16 27.5C8.54416 27.5 2.5 21.4558 2.5 14Z"
      stroke="#E3E7ED"
    />
    <g clipPath="url(#clip0_1088_93909)">
      <path
        d="M16 18.9976C18.7614 18.9976 21 16.759 21 13.9976C21 11.2361 18.7614 8.99756 16 8.99756C13.2386 8.99756 11 11.2361 11 13.9976C11 16.759 13.2386 18.9976 16 18.9976Z"
        fill="white"
      />
      <path
        d="M16.0003 9.00002H24.6591C23.7817 7.47976 22.5195 6.21731 20.9994 5.3396C19.4793 4.4619 17.7549 3.99988 15.9996 4C14.2443 4.00012 12.52 4.46238 11 5.3403C9.48007 6.21822 8.21803 7.48085 7.34082 9.00123L11.6702 16.5L11.6741 16.499C11.2337 15.7395 11.0013 14.8774 11.0003 13.9995C10.9993 13.1215 11.2298 12.2589 11.6685 11.4984C12.1072 10.7379 12.7386 10.1066 13.4991 9.66795C14.2596 9.22932 15.1223 8.99894 16.0003 9.00002Z"
        fill="url(#paint0_linear_1088_93909)"
      />
      <path
        d="M16.0003 17.9582C18.1865 17.9582 19.9587 16.186 19.9587 13.9998C19.9587 11.8137 18.1865 10.0415 16.0003 10.0415C13.8142 10.0415 12.042 11.8137 12.042 13.9998C12.042 16.186 13.8142 17.9582 16.0003 17.9582Z"
        fill="#1A73E8"
      />
      <path
        d="M20.3293 16.5014L15.9998 24.0002C17.7551 24.0004 19.4795 23.5385 20.9997 22.6609C22.5198 21.7833 23.7821 20.521 24.6597 19.0008C25.5372 17.4806 25.999 15.7562 25.9987 14.0009C25.9984 12.2456 25.536 10.5213 24.6579 9.00146H15.9991L15.998 9.00534C16.876 9.00365 17.7388 9.23344 18.4997 9.67154C19.2605 10.1096 19.8923 10.7406 20.3316 11.5007C20.7708 12.2609 21.0018 13.1234 21.0014 14.0014C21.001 14.8793 20.7692 15.7416 20.3293 16.5014Z"
        fill="url(#paint1_linear_1088_93909)"
      />
      <path
        d="M11.6705 16.5012L7.34111 9.00244C6.46324 10.5224 6.00104 12.2468 6.00098 14.0021C6.00091 15.7574 6.46299 17.4817 7.34074 19.0018C8.21849 20.5219 9.48098 21.784 11.0013 22.6614C12.5215 23.5388 14.246 24.0004 16.0013 23.9999L20.3307 16.5011L20.3279 16.4983C19.8904 17.2595 19.26 17.8918 18.5002 18.3316C17.7404 18.7715 16.878 19.0032 16.0001 19.0035C15.1221 19.0038 14.2596 18.7726 13.4995 18.3333C12.7394 17.894 12.1086 17.2621 11.6705 16.5012Z"
        fill="url(#paint2_linear_1088_93909)"
      />
    </g>
    <rect x="15" y="44" width="2" height="36" rx="1" fill="#E3E7ED" />
    <defs>
      <linearGradient
        id="paint0_linear_1088_93909"
        x1="7.34082"
        y1="10.25"
        x2="24.6591"
        y2="10.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D93025" />
        <stop offset="1" stopColor="#EA4335" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1088_93909"
        x1="14.6337"
        y1="23.8665"
        x2="23.2928"
        y2="8.86838"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCC934" />
        <stop offset="1" stopColor="#FBBC04" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1088_93909"
        x1="17.0828"
        y1="23.3756"
        x2="8.42365"
        y2="8.37744"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1E8E3E" />
        <stop offset="1" stopColor="#34A853" />
      </linearGradient>
      <clipPath id="clip0_1088_93909">
        <rect width="20" height="20" fill="white" transform="translate(6 4)" />
      </clipPath>
    </defs>
  </svg>
);

export default ChromeIcon;
