import React, { useEffect, lazy, Suspense } from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { TransitionGroup } from 'react-transition-group';

import {
  ChromeExtensionBanner,
  GlobalHeader,
  PermissionsBanner,
  Scrollbar,
  AddChromeExtensionModal,
} from './components';
import { Sidebar } from './components/SoundWave/components/Sidebar/Sidebar';
import { Maintenance, ProfileRedirect } from './pages';
import { RouteI, User } from './types';
import { getCurrentPath } from './components/utils';
import PersonalQForIframe from './components/PersonalQForIframe/PersonalQForIframe';
import { Submenu } from './components/Submenu/Submenu';
import { AccountSettings } from './pages/AccountSettings/AccountSettings';
import { TeamsForRedirect } from './pages/TeamsForRedirect/TeamsForRedirect';
import { DealsForRedirect } from './pages/MyDeals/PageToRedirect/DealsForRedirect';
import { OnboardingModal } from './components/OnboardingModal/OnboardingModal';
import { ExtensionNIWarnModal } from './components/ExtensionNIWarnModal/ExtensionNIWarnModal';
import { AddInfoModal } from './components/AddInfoModal/AddInfoModal';
import { NonPaidUserModal } from './components/NonPaidUserModal/NonPaidUserModal';
import { PATHS } from './constants/paths';
import { AddAccountModal } from './components/AddAccountModal/AddAccountModal';
import { AddMemberModal } from './components/AddMemberModal/AddMemberModal';
import { AssignAccountModal } from './components/AssignAccountModal/AssignAccountModal';
import { BottomNotification } from './components/SoundWave/components/BottomNotification/BottomNotification';
import { AddNoteModal } from './components/AddNoteModal/AddNoteModal';
import { EngagementsRedirect } from './pages/MyDeals/EngagementsRedirect';
import { UnassignEventModal } from './components/UnassignEventModal/UnassignEventModal';
import { useAppSelector } from './store/hooks';
import { useFirebase } from './context';
import { AddDetailsModal } from './components/AddDetailsModal/AddDetailsModal';

const Account = lazy(() => import('./pages/Account/Account'));

const Apps = lazy(() => import('./pages/Apps/Apps'));

const Users = lazy(() => import('./pages/Users/Users'));

const MyOrganization = lazy(
  () => import('./pages/MyOrganization/MyOrganization')
);

const MyRelationships = lazy(
  () => import('./pages/MyRelationships/MyRelationships')
);

const RelationshipDetails = lazy(
  () =>
    import(
      './pages/MyRelationships/components/relationshipDetails/RelationshipDetails'
    )
);

const ThreadDetails = lazy(() => import('./pages/ThreadDetails/ThreadDetails'));

const MeetingDetails = lazy(
  () => import('./pages/MeetingDetails/MeetingDetails')
);

const MeetingDetailsOld = lazy(
  () => import('./pages/MeetingDetails/MeetingDetailsOld')
);

const MyMeetings = lazy(() => import('./pages/MyMeetings/MyMeetings'));

const Billing = lazy(() => import('./pages/Billing/Billing'));

const Stats = lazy(() => import('./pages/Analytics/Stats'));

const TeamQ = lazy(() => import('./pages/TeamQ/TeamQ'));

const Analytics = lazy(() => import('./pages/Analytics/Analytics'));

const Teams = lazy(() => import('./pages/Teams/Teams'));

const LearningHub = lazy(() => import('./pages/LeangingHub/LearningHub'));

export const ROUTES: RouteI[] = [
  {
    path: PATHS.DEALS_REDIRECT,
    Component: DealsForRedirect,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.RELATIONSHIPS,
    Component: MyRelationships,
    label: 'My Relationships',
    tooltip: 'My Relationships',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.ENGAGEMENTS,
    Component: EngagementsRedirect,
    label: 'My Engagements ',
    tooltip: 'My Engagements',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ENGAGEMENT_DETAILS,
    Component: EngagementsRedirect,
    label: 'My Engagements',
    tooltip: 'My Engagements',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.THREAD_DETAILS,
    Component: ThreadDetails,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.TOUCH_POINT_DETAILS,
    Component: ThreadDetails,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.EMAIL_STATS,
    Component: Stats,
    label: 'My Performance Q  ',
    tooltip: 'My Performance Q',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.PERFORMACE_Q,
    Component: Stats,
    label: 'My Performance Q  ',
    tooltip: 'My Performance Q',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.TEAM_ANALYTICS,
    Component: TeamQ,
    label: 'Overview',
    tooltip: 'Team Q',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.RELATIONSHIP_DETAILS,
    Component: RelationshipDetails,
    label: '',
    tooltip: '',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.MEETING_DETAILS,
    Component: MeetingDetails,
    label: 'My Meetings',
    tooltip: 'My Meetings',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  ...(process.env.REACT_APP_MODE !== 'prod'
    ? [
        {
          path: PATHS.MEETING_DETAILS_OLD,
          Component: MeetingDetailsOld,
          label: 'My Meetings',
          tooltip: 'My Meetings',
          roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
        },
      ]
    : []),
  {
    path: PATHS.MEETING_RECORDS,
    Component: MyMeetings,
    label: 'My Meetings',
    tooltip: 'Meeting Records',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.MEETING_RECORD,
    Component: MeetingDetails,
    label: 'My Meetings',
    tooltip: 'Meeting Records',
    roles: ['owner', 'admin', 'superAdmin', 'dealmaker'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_APPS,
    Component: Apps,
    label: 'Apps',
    tooltip: 'Apps',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_TEAMS,
    Component: Teams,
    label: 'Teams',
    tooltip: 'Teams',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.TEAMS_REDIRECT,
    Component: TeamsForRedirect,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_ORGANIZATION,
    Component: MyOrganization,
    label: 'My Organization',
    tooltip: 'My Organization',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ORGANIZATION_DETAILS,
    Component: MyOrganization,
    label: 'My Organization',
    tooltip: 'My Organization',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.PERSONAL_Q,
    Component: Analytics,
    label: 'Performance Q',
    tooltip: 'Analytics',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.PERSONAL_Q_ANALYTICS,
    Component: Analytics,
    label: 'Performance Q ',
    tooltip: 'Analytics',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.PERSONAL_Q_COMPETENCE,
    Component: PersonalQForIframe,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
    isIframe: true,
  },
  {
    path: PATHS.APPS,
    Component: Apps,
    label: 'Apps',
    tooltip: 'My Apps',
    roles: ['dealmaker'],
  },
  {
    path: PATHS.ACCOUNT,
    Component: Account,
    label: 'Account',
    tooltip: 'My Account',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.LEARNING_HUB,
    Component: LearningHub,
    label: 'Onboarding Wizard',
    tooltip: 'Onboarding Wizard',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS,
    Component: AccountSettings,
    label: 'Admin Settings',
    tooltip: 'Admin Settings',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_USERS,
    Component: Users,
    label: 'Users',
    tooltip: 'Users',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.USERS,
    Component: Users,
    label: 'Users',
    tooltip: 'Users',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.ADMIN_SETTINGS_BILLING,
    Component: Billing,
    label: 'Billing',
    tooltip: 'Billing',
    roles: ['owner', 'admin', 'superAdmin'],
  },
  {
    path: PATHS.PROFILE_REDIRECT,
    Component: ProfileRedirect,
    label: '',
    tooltip: '',
    roles: ['dealmaker', 'owner', 'admin', 'superAdmin'],
  },
];

interface ProtectedRouteProps extends RouteProps {
  roles: string[];
  user: User | null;
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  roles,
  user,
  children,
  ...props
}: ProtectedRouteProps) => {
  const { isInitialized, isLoggedIn } = useFirebase();

  if (isInitialized && !isLoggedIn) {
    return <Redirect to="/" />;
  }

  return roles.includes(user?.role as string) && user?.isEmailVerified ? (
    <Route {...props}>{() => children}</Route>
  ) : null;
};

export const AppRouting: React.FC = (): JSX.Element | null => {
  const user = useAppSelector((state) => state.auth.user);

  const isMaintenanceMode = useAppSelector(
    (state) => state.global.isMaintenanceMode
  );

  const { pathname } = useLocation();

  const relationshipsMatch = useRouteMatch(PATHS.RELATIONSHIPS);
  const meetingRecordsMatch = useRouteMatch(PATHS.MEETING_RECORDS);
  const teamQMatch = useRouteMatch(PATHS.TEAM_ANALYTICS);
  const performanceQMatch = useRouteMatch(PATHS.PERFORMACE_Q);
  const appsMatch = useRouteMatch(PATHS.APPS);
  const appsAdminMatch = useRouteMatch(PATHS.ADMIN_SETTINGS_APPS);
  const billingMatch = useRouteMatch(PATHS.ADMIN_SETTINGS_BILLING);
  const learningHubMatch = useRouteMatch(PATHS.LEARNING_HUB);

  const { boot } = useIntercom();

  const currentRoute = ROUTES.find(
    ({ path }) => getCurrentPath(path, user) === pathname
  );

  const attrs = {
    scrollable: {
      className: 'App__scrollable',
    },
    bannersWrapper: {
      className: 'banners-wrapper',
    },
  };

  useEffect(() => {
    if (user && user.userHash) {
      const userInfo = {
        userId: `${user.orgId}|${user.userId}`,
        name: user.name,
        email: user.email,
        userHash: user.userHash,
        customAttributes: {
          userId: user.userId,
          orgId: user.orgId,
        },
      };

      boot(userInfo);
    } // eslint-disable-next-line
  }, [user?.userId]);

  const defineWrapperClassName = () => {
    if (
      relationshipsMatch?.isExact ||
      meetingRecordsMatch?.isExact ||
      teamQMatch ||
      performanceQMatch ||
      appsMatch ||
      appsAdminMatch ||
      billingMatch ||
      learningHubMatch
    ) {
      return 'page page--new-design page--new-design-white';
    }

    if (currentRoute?.isIframe) {
      return '';
    }

    return 'page page--new-design';
  };

  const defineContentClassName = () => {
    if (currentRoute?.label === 'Stats') {
      return 'analytics-stats__page-content';
    }

    if (currentRoute?.isIframe) {
      return '';
    }

    return 'page__content';
  };

  const conditionalPageClassName = defineWrapperClassName();

  const conditionalContentClassName = defineContentClassName();

  if (isMaintenanceMode) {
    return <Maintenance />;
  }

  return (
    <div className={currentRoute?.isIframe ? '' : 'App'}>
      <Sidebar />
      <Submenu />
      {/* <ExtensionNIWarnModal /> */}
      {/* <AddInfoModal /> */}
      {/* <OnboardingModal /> */}
      <AddDetailsModal />
      <NonPaidUserModal />
      <AddAccountModal />
      <AddMemberModal />
      <AssignAccountModal />
      <UnassignEventModal />
      <AddNoteModal />
      <AddChromeExtensionModal />
      <BottomNotification />
      <Scrollbar {...attrs.scrollable}>
        <div className={conditionalPageClassName}>
          {!currentRoute?.isIframe ? <GlobalHeader /> : null}
          {!currentRoute?.isIframe ? (
            <div {...attrs.bannersWrapper}>
              <ChromeExtensionBanner />
              <PermissionsBanner />
            </div>
          ) : null}
          <div className={conditionalContentClassName}>
            <TransitionGroup>
              <Switch>
                {ROUTES.map((route) => {
                  const { path, Component, roles } = route;
                  return (
                    <ProtectedRoute
                      roles={roles}
                      key={path}
                      exact
                      path={path}
                      user={user}
                    >
                      <Suspense fallback={null}>
                        <Component />
                      </Suspense>
                    </ProtectedRoute>
                  );
                })}
                <Route path="*">
                  <Redirect to="/not-found" />
                </Route>
              </Switch>
            </TransitionGroup>
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};
