import React from 'react';

export const LearningHubIcon: React.FC<
  React.SVGProps<SVGSVGElement> & {
    isActive?: boolean;
  }
> = ({ isActive, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path
      d="M20.9799 3.01986C20.1099 2.14986 18.8799 1.80986 17.6899 2.10986L7.88986 4.55986C6.23986 4.96986 4.96986 6.24986 4.55986 7.88986L2.10986 17.6999C1.80986 18.8899 2.14986 20.1199 3.01986 20.9899C3.67986 21.6399 4.54986 21.9999 5.44986 21.9999C5.72986 21.9999 6.01986 21.9699 6.29986 21.8899L16.1099 19.4399C17.7499 19.0299 19.0299 17.7599 19.4399 16.1099L21.8899 6.29986C22.1899 5.10986 21.8499 3.87986 20.9799 3.01986ZM11.9999 15.8799C9.85986 15.8799 8.11986 14.1399 8.11986 11.9999C8.11986 9.85986 9.85986 8.11986 11.9999 8.11986C14.1399 8.11986 15.8799 9.85986 15.8799 11.9999C15.8799 14.1399 14.1399 15.8799 11.9999 15.8799Z"
      fill="white"
    />
  </svg>
);
