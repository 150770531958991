import React from 'react';

const ExtensionAddedCompletedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M2.5 14C2.5 6.54416 8.54416 0.5 16 0.5C23.4558 0.5 29.5 6.54416 29.5 14C29.5 21.4558 23.4558 27.5 16 27.5C8.54416 27.5 2.5 21.4558 2.5 14Z"
      fill="white"
    />
    <path
      d="M2.5 14C2.5 6.54416 8.54416 0.5 16 0.5C23.4558 0.5 29.5 6.54416 29.5 14C29.5 21.4558 23.4558 27.5 16 27.5C8.54416 27.5 2.5 21.4558 2.5 14Z"
      stroke="#10B981"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0003 22.3332C20.6027 22.3332 24.3337 18.6022 24.3337 13.9998C24.3337 9.39746 20.6027 5.6665 16.0003 5.6665C11.398 5.6665 7.66699 9.39746 7.66699 13.9998C7.66699 18.6022 11.398 22.3332 16.0003 22.3332ZM20.1307 12.2312C20.4565 11.9061 20.4569 11.3784 20.1318 11.0527C19.8066 10.727 19.279 10.7265 18.9533 11.0517L14.8175 15.1801L13.0479 13.4105C12.7225 13.0851 12.1948 13.0851 11.8694 13.4105C11.544 13.736 11.544 14.2636 11.8694 14.589L14.2277 16.9474C14.553 17.2726 15.0802 17.2728 15.4057 16.9479L20.1307 12.2312Z"
      fill="#10B981"
    />
  </svg>
);

export default ExtensionAddedCompletedIcon;
