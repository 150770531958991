import {
  User as FirebaseUser,
  signInWithCustomToken,
  GoogleAuthProvider,
  reauthenticateWithPopup,
} from 'firebase/auth';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FirebaseContextValue } from './firebase.types';
import { firebaseAuth } from './firebase.setup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  endConnectAnalyzeEmails,
  endConnectCalendar,
  endConnectSendEmails,
  endSignIn,
  initAuth,
  startConnectAnalyzeEmails,
  startConnectCalendar,
  startConnectSendEmails,
  startSignIn,
  throwConnectAnalyzeEmailsError,
  throwConnectCalendarError,
  throwConnectSendEmailsError,
  throwSignInError,
} from '../../store/auth/auth.slice';
import {
  getIdentity,
  getUserConnections,
  signOut as signOutRequest,
} from '../../store/auth/auth.thunks';
import { BROADCAST_CHANNEL_NAME } from '../../constants/config';
import { BroadcastChannelMessageType } from '../../types/broadcastChannel.types';
import { notifyExtension } from '../../utils/notifyExtension';
import { ChromeMessageType } from '../../types/chromeMessage.types';

const FirebaseContext = createContext<FirebaseContextValue>({
  firebaseUser: null,
  isLoggedIn: false,
  isInitialized: false,
  isSignInError: false,
  signIn: () => {},
  signOut: () => {},
  connectAnalyzeEmails: () => {},
  connectSendEmails: () => {},
  connectCalendar: () => {},
});

export const FirebaseProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const isLoggedInValue = useAppSelector((state) => state.auth.isLoggedIn);
  const isInitialized = useAppSelector((state) => state.auth.isInitialized);
  const isSignInError = useAppSelector((state) => state.auth.isError.signIn);
  const isSignInLoading = useAppSelector(
    (state) => state.auth.isLoading.signIn
  );

  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);

  const isLoggedIn = isLoggedInValue && !isSignInLoading && !isSignInError;

  const signOut = useCallback(async () => {
    try {
      await dispatch(signOutRequest()).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      firebaseAuth.signOut();
    }
  }, [dispatch]);

  const signIn = useCallback(
    async (token: string) => {
      try {
        dispatch(startSignIn());
        await signInWithCustomToken(firebaseAuth, token);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(throwSignInError());
        firebaseAuth.signOut();
      } finally {
        dispatch(endSignIn());
      }
    },
    [dispatch]
  );

  const connectAnalyzeEmails = useCallback(async () => {
    if (firebaseUser) {
      try {
        dispatch(startConnectAnalyzeEmails());

        const provider = new GoogleAuthProvider();

        provider.setCustomParameters({
          access_type: 'offline',
          include_granted_scopes: 'true',
          login_hint: firebaseUser?.email || '',
        });

        provider.addScope('email');
        provider.addScope('https://www.googleapis.com/auth/gmail.readonly');

        await reauthenticateWithPopup(firebaseUser, provider);

        dispatch(getUserConnections());

        notifyExtension({
          type: ChromeMessageType.UPDATE_IDENTITY,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(throwConnectAnalyzeEmailsError());
      } finally {
        dispatch(endConnectAnalyzeEmails());
      }
    }
  }, [dispatch, firebaseUser]);

  const connectSendEmails = useCallback(async () => {
    if (firebaseUser) {
      try {
        dispatch(startConnectSendEmails());

        const provider = new GoogleAuthProvider();

        provider.setCustomParameters({
          access_type: 'offline',
          include_granted_scopes: 'true',
          login_hint: firebaseUser?.email || '',
        });

        provider.addScope('https://www.googleapis.com/auth/gmail.send');
        provider.addScope('https://www.googleapis.com/auth/contacts');

        await reauthenticateWithPopup(firebaseUser, provider);

        dispatch(getUserConnections());
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(throwConnectSendEmailsError());
      } finally {
        dispatch(endConnectSendEmails());
      }
    }
  }, [dispatch, firebaseUser]);

  const connectCalendar = useCallback(async () => {
    if (firebaseUser) {
      try {
        dispatch(startConnectCalendar());

        const provider = new GoogleAuthProvider();

        provider.setCustomParameters({
          access_type: 'offline',
          include_granted_scopes: 'true',
          login_hint: firebaseUser?.email || '',
        });

        provider.addScope('https://www.googleapis.com/auth/calendar');

        await reauthenticateWithPopup(firebaseUser, provider);

        dispatch(getUserConnections());
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(throwConnectCalendarError());
      } finally {
        dispatch(endConnectCalendar());
      }
    }
  }, [dispatch, firebaseUser]);

  const firebaseContextValue = useMemo(
    () => ({
      firebaseUser,
      isLoggedIn,
      isInitialized,
      isSignInError,
      signIn,
      signOut,
      connectAnalyzeEmails,
      connectSendEmails,
      connectCalendar,
    }),
    [
      connectAnalyzeEmails,
      connectCalendar,
      connectSendEmails,
      firebaseUser,
      isInitialized,
      isLoggedIn,
      isSignInError,
      signIn,
      signOut,
    ]
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getIdentity());
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((currentUser) => {
      setFirebaseUser(currentUser);
      dispatch(initAuth(!!currentUser));
    });

    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
    const cleanUp = () => {
      controller.abort();
      broadcastChannel.close();
    };

    broadcastChannel.addEventListener(
      'message',
      (event) => {
        if (event.origin !== window.origin) {
          return;
        }

        if (event.data?.type === BroadcastChannelMessageType.LOGOUT) {
          signOut();
          cleanUp();
        }
      },
      {
        signal: controller.signal,
      }
    );

    return cleanUp;
  }, [signOut]);

  return (
    <FirebaseContext.Provider value={firebaseContextValue}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = (): FirebaseContextValue =>
  useContext(FirebaseContext);
