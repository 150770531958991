import React from 'react';

const LinkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1088_86829)">
      <path
        d="M4.70044 3.09035C4.33225 3.09035 4.03378 3.38882 4.03378 3.75701C4.03378 4.1252 4.33225 4.42368 4.70044 4.42368L10.6334 4.42368L3.28623 11.7709C3.02588 12.0312 3.02588 12.4534 3.28623 12.7137C3.54658 12.974 3.96869 12.974 4.22904 12.7137L11.5762 5.36649L11.5762 11.2995C11.5762 11.6677 11.8747 11.9662 12.2429 11.9662C12.6111 11.9662 12.9096 11.6677 12.9096 11.2995L12.9096 3.75701C12.9096 3.38882 12.6111 3.09035 12.2429 3.09035L4.70044 3.09035Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1088_86829">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkIcon;
