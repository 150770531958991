import React, { useEffect } from 'react';
import {
  closeAddChromeExtensionModal,
  openAddChromeExtensionModal,
} from '../../store/auth/auth.slice';
import { sendDontShowInstallExtensionPopup } from '../../store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ButtonVariant, Modal } from '../SoundWave';
import { ChromeIcon, CompletedIcon, LinkIcon } from './icons';
import ExtensionAddedCompletedIcon from './icons/ExtensionAddedCompletedIcon';

const AddChromeExtensionModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const isOpened = useAppSelector(
    (state) => state.auth.addChromeExtensionModal.isOpened
  );

  const isChromeExtensionInstalled = window?.chrome?.runtime;

  useEffect(() => {
    // const wrongcheck =
    //   user &&
    //   !user.showDashboardWelcomePopup &&
    //   !user.showInstallExtensionPopup;

    if (
      user &&
      !user.showDashboardWelcomePopup &&
      user.showInstallExtensionPopup
    ) {
      dispatch(openAddChromeExtensionModal());
    }
  }, [dispatch, isOpened, user]);

  const attrs = {
    modal: {
      isOpened,
      width: 496,
      closeOnClickOutside: true,
      className: 'add-chrome-extension',
      headerProps: {
        title: 'Thank You for subscribing!',
      },
      footerProps: {
        isCancelButtonHidden: true,
        confirmButtonVariant: ButtonVariant.Primary,
        confirmButtonText: isChromeExtensionInstalled
          ? 'Let’s Start'
          : 'Add to Chrome',
        confirmButtonIcon: isChromeExtensionInstalled ? undefined : LinkIcon,
        onConfirm: () => {
          if (!isChromeExtensionInstalled) {
            window.open(
              'https://chromewebstore.google.com/detail/substrata-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka',
              '_blank'
            );
          }

          dispatch(closeAddChromeExtensionModal());
          dispatch(sendDontShowInstallExtensionPopup());
        },
      },
      onClose: () => {
        dispatch(closeAddChromeExtensionModal());
        dispatch(sendDontShowInstallExtensionPopup());
      },
    },
    content: {
      className: 'add-chrome-extension__content',
    },
    item: {
      className: 'add-chrome-extension__item',
    },
    itemIcon: {
      className: 'add-chrome-extension__item-icon',
    },
    itemText: {
      className: 'add-chrome-extension__item-text',
    },
    itemTitle: {
      className: 'add-chrome-extension__item-title',
    },
  };

  const chromeExtensionItem = isChromeExtensionInstalled ? (
    <div {...attrs.item}>
      <ExtensionAddedCompletedIcon {...attrs.itemIcon} />
      <div {...attrs.itemText}>
        <span {...attrs.itemTitle}>Add Substrata to Chrome</span>
      </div>
    </div>
  ) : (
    <div {...attrs.item}>
      <ChromeIcon {...attrs.itemIcon} />
      <div {...attrs.itemText}>
        <span {...attrs.itemTitle}>Add Substrata to Chrome</span>
        <span>
          To access Q for Email and other features, add Substrata to your Chrome
          browser
        </span>
      </div>
    </div>
  );

  return (
    <Modal {...attrs.modal}>
      <div {...attrs.content}>
        <div {...attrs.item}>
          <CompletedIcon {...attrs.itemIcon} />
          <div {...attrs.itemText}>
            <span {...attrs.itemTitle}>Subscription confirmed</span>
          </div>
        </div>
        <div {...attrs.item}>
          <CompletedIcon {...attrs.itemIcon} />
          <div {...attrs.itemText}>
            <span {...attrs.itemTitle}>Complete Your Profile</span>
          </div>
        </div>
        {chromeExtensionItem}
      </div>
    </Modal>
  );
};

export default AddChromeExtensionModal;
